import React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/shared/seo"
import ContactBriefly from "../components/shared/contact-briefly/contact-briefly";
import BannerWithImg from "../components/shared/banner-with-img/banner-with-img";
import ServiceDesc from "../components/services/service-desc/service-desc";
import ServiceFeatures from "../components/services/service-features/service-features";
import ServiceTechnologies from "../components/services/service-technologies/service-technologies";

import webAppsBannerImg from "../images/banners-img/web-apps-banner-img-tiny.jpg"
import webappIcon from "../images/icons/web-app.svg"
import checkIcon from "../images/icons/check.svg"

import dockerLogo from "../images/technologies/docker.svg"
import socketioLogo from "../images/technologies/socketio.svg"
import digitaloceanLogo from "../images/technologies/digitalocean.svg"
import nextjsLogo from "../images/technologies/nextjs.svg"
import nginxLogo from "../images/technologies/nginx.svg"
import npmLogo from "../images/technologies/npm.svg"
import reduxLogo from "../images/technologies/redux.svg"
import webpackLogo from "../images/technologies/webpack.svg"
import apolloLogo from "../images/technologies/apollo.svg"
import reactivexLogo from "../images/technologies/reactivex.svg"
import babelLogo from "../images/technologies/babel.svg"
import typescriptLogo from "../images/technologies/typescript.svg"
import vueLogo from "../images/technologies/vue.svg"
import mongoLogo from "../images/technologies/mongo.svg"
import nodejsLogo from "../images/technologies/nodejs.svg"
import reactLogo from "../images/technologies/react.svg"
import angularLogo from "../images/technologies/angular.svg"
import graphqlLogo from "../images/technologies/graphql.svg"
import meteorLogo from "../images/technologies/meteor.svg"

// import mobileappIcon from "../images/icons/mobile-app.svg"
// import consultingIcon from "../images/icons/consulting.svg"

const features = [
	{ id: "custom", title: "Desarrollo personalizado", desc: "Alineado con las necesidades que tenga su negocio.", iconSrc: checkIcon},
	{ id: "admin", title: "Módulo de administración", desc: "Total control del contenido y usuarios de la aplicación.", iconSrc: checkIcon},
	{ id: "security", title: "Seguridad", desc: "Manejo y protección de la información.", iconSrc: checkIcon},
	{ id: "cloud", title: "Soporte y Cloud", desc: "Soporte y servicios escalables de cloud.", iconSrc: checkIcon}
];

const technologies = [
	{ id: "docker", url:"", logoSrc: dockerLogo},
	{ id: "socketio", url:"", logoSrc: socketioLogo},
	{ id: "digitalocean", url:"", logoSrc: digitaloceanLogo},
	{ id: "nextjs", url:"", logoSrc: nextjsLogo},
    { id: "nginx", url:"", logoSrc: nginxLogo},
	{ id: "npm", url:"", logoSrc: npmLogo},
	{ id: "redux", url:"", logoSrc:reduxLogo},
	{ id: "webpack", url:"", logoSrc: webpackLogo},
	{ id: "apollo", url:"", logoSrc: apolloLogo},
	{ id: "reactivex", url:"", logoSrc: reactivexLogo},
	{ id: "babel", url:"", logoSrc: babelLogo},
	{ id: "typescript", url:"", logoSrc: typescriptLogo},
	{ id: "vue", url:"", logoSrc: vueLogo},
	{ id: "mongo", url:"", logoSrc: mongoLogo},
	{ id: "nodejs", url:"", logoSrc: nodejsLogo},
	{ id: "react", url:"", logoSrc: reactLogo},
	{ id: "angular", url:"", logoSrc: angularLogo},
	{ id: "graphql", url:"", logoSrc: graphqlLogo},
	{ id: "meteor", url:"", logoSrc: meteorLogo},
    
];

const ServicesWebAppsPage = () => {

    return (
        <Layout>
            <SEO title="Desarrollo de aplicaciones web" description="Genere valor con sus productos y servicios de manera digital a través de una aplicación web. Desarrollo web Costa Rica." lang="es" path="aplicaciones-web/" meta={[
                {
					property: `og:type`,
					content: `website`,
                },
                {
                    property: `og:image`,
                    content: 'https://res.cloudinary.com/spheredevs/image/upload/v1600464750/SphereDevs/web-apps-banner-img-tiny.jpg'
                },
                {
                    property: `og:locale`,
                    content: 'es_CR',
                },
				{
					name: `twitter:card`,
					content: `summary`,
				},
				{
					name: `twitter:title`,
					content: "Desarrollo de aplicaciones web",
				},
				{
					name: `twitter:description`,
					content: "Genere valor con sus productos y servicios de manera digital a través de una aplicación web. Desarrollo web Costa Rica.",
                },
                {
                    name: `twitter:creator`,
                    content: `@SphereDevs`
                },
                {
                    name: `twitter:site`,
                    content: `@SphereDevs`,
                }
            ]}/>
            <BannerWithImg urlImage="https://res.cloudinary.com/spheredevs/image/upload/v1600464750/SphereDevs/web-apps-banner-img-tiny.jpg" description="Desarrollo de aplicaciones web a la medida" />
            <ServiceDesc text="El siguiente paso para digitalizar su negocio y operaciones es tener una aplicación web. Desarrollamos aplicaciones web que le permiten tener una mejor experiencia al interactuar y comunicarse con sus clientes y colaboradores." iconSrc={webappIcon}/>
            <ServiceFeatures title="Todos nuestos desarrollos cuentan con:" elements={features} />
            <ServiceTechnologies title="Desarrolle sus proyectos con lo mejor de la industria" elements={technologies} />
            <ContactBriefly description="¿Tienes algo en mente? No esperes más!" contactUsAction="/contacto" contactUsLabel="Iniciar proyecto" />
        </Layout>
    )
}

export default ServicesWebAppsPage